<template>
  <div>
    <v-card outlined class="relative catalog-image" @click="addCatalog">
      <v-img
        :lazy-src="imageHelpers.url(item.imageSrc)"
        max-height="300"
        max-width="300"
        :src="imageHelpers.url(item.imageSrc, { width: 200, height: 200 })"
        alt=""
        srcset=""
      ></v-img>
      <v-icon v-if="inCatalog" class="icon-check" size="20" color="green" right>fas fa-check-circle</v-icon>
    </v-card>
    <v-row>
      <v-col cols="12" class="font-weight-bold catalog-title pb-0">
        {{ item.title }}
      </v-col>
      <v-col cols="12" class="pb-0 pt-2">
        <!-- From <span class="catalog-price">${{ item.price }}</span> -->
      </v-col>
      <!-- <v-col cols="12" class="pb-0 pt-1 caption">Processing Time: {{ item.processTime }}</v-col> -->
      <v-col cols="12" class="py-0 d-flex align-items-center justify-content-center">
        <v-btn @click="addCatalog" v-if="!inCatalog" outlined class="caption font-weight-bold col-9 catalog-btn-size">
          <v-icon dark size="10" class="mr-3">fas fa-plus</v-icon>
          Add product
        </v-btn>
        <v-btn @click="addCatalog" v-if="inCatalog" outlined class="caption font-weight-bold col-9 catalog-btn-size">
          <v-icon dark size="10" class="mr-3">fas fa-minus</v-icon>
          Remove product
        </v-btn>
        <v-btn outlined class="caption font-weight-bold col-2 offset-1 catalog-btn-size">
          <v-icon dark size="10" class="">fas fa-download</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import imageHelpers from '@/helpers/image';
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imageHelpers,
      inCatalog: false,
    };
  },
  methods: {
    addCatalog() {
      let index = this.$parent.list.findIndex(item => item === this.item._id);
      console.log(`index`, index);
      if (index > -1 || this.$parent.list.length === 0) {
        this.inCatalog = !this.inCatalog;
        this.$parent.addCatalog(this.item._id);
      }
    },
  },
};
</script>

<style lang="scss">
.icon-check {
  position: absolute !important;
  top: 8px;
  right: 8px;
}
.catalog-title {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 13px;
}
.catalog-btn-size {
  height: 32px !important;
  min-width: 36px !important;
}
.catalog-price {
  color: green;
  font-weight: bold;
}
.catalog-image {
  &:hover {
    cursor: pointer;
  }
}
</style>
