<template>
  <div>
    <v-row v-if="!isLoading">
      <v-col cols="3" v-for="(resource, k) in document" :key="k">
        <ResourceItem :item="resource" />
      </v-col>
    </v-row>
    <v-row v-if="isLoading">
      <v-col cols="12" class="d-flex justify-center mt-8">
        <!-- <page-loader /> -->
        <v-skeleton-loader width="100%" type="card"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import resourceListMixin from '@/mixins/resource-list';
import ResourceItem from './ResouceItem.vue';
export default {
  mixins: [resourceListMixin],
  components: { ResourceItem },
  data() {
    return {
      list: [],
      resourceType: 'catalog',
    };
  },
  created() {
    // if (this.$route.name === 'website.apps.print-hub.catalog') {
    //   this.resourceType = 'catalog';
    // }
  },
  methods: {
    addCatalog(itemId) {
      let index = this.list.findIndex(item => item === itemId);
      console.log(`index splice`, index, this.list);
      if (index > -1) {
        this.list.splice(index, 1);
      } else {
        this.list.push(itemId);
      }
      this.$emit(`disabledCreate`, this.list.length === 0 ? true : false);
    },
    createCampaign() {
      this.$store.commit('setListMockupSelected', this.list);
      localStorage.setItem(`catalog`, this.list);
      this.$router.push({ name: 'print-hub-editor', params: { mockupId: 'mockupId' } });
    },
    updateCampaign() {
      this.$store.commit('updateList', this.list);
    },
  },
  mounted() {
    if (this.list.length === 0) {
      this.$emit('changeList', true);
    } else {
      this.$emit('changeList', false);
    }
  },
  watch: {
    list: {
      deep: true,
      handler() {
        if (this.list.length === 0) {
          this.$emit('changeList', true);
        } else {
          this.$emit('changeList', false);
        }
      },
    },
  },
};
</script>
<style></style>
